import Vue from "vue";
import App from "@/App.vue";
// import '@/registerServiceWorker';
import vuewhitelabel from "./plugins/vuewhitelabel";
import router from "@/router";
import store from "@/store";
import { i18n } from "@/plugins/vuei18n";
import vuetify from "@/plugins/vuetify";
import '@/assets/css/style.css';

import axios from "axios";
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = process.env.VUE_APP_APIPATH;
axios.defaults.headers.common['Authorization'] = process.env.VUE_APP_APITOKEN;

import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueGapi from 'vue-gapi';
Vue.use(VueGapi, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
});

Vue.use(vuewhitelabel);

window.fbAsyncInit = function() {
    window.FB.init({
        appId            : process.env.VUE_APP_FACEBOOK_APP_ID,
        autoLogAppEvents : false,
        xfbml            : false,
        status           : false,
        version          : process.env.VUE_APP_FACEBOOK_GRAPH_VERSION
    });
};

Vue.config.devtools = process.env.NODE_ENV === "development";
Vue.config.productionTip = process.env.NODE_ENV === "production";

new Vue({ 
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App) 
}).$mount("#app")
