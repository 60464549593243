<style lang="scss">
    html, body {
        overflow-y: auto;
    }

    .letter-spacing-0 {
        letter-spacing: 0 !important;
    }

    .text-decoration-none {
        text-decoration: none !important;
    }
</style>

<template>
    <v-app id="main" style="background: #041e49;">
        <v-main>
            <v-container
              class="fill-height justify-center flex-column"
              fluid
            >   
                <div>
                    <Alert />
                    <div class="text-center">
                        <v-progress-circular
                          v-if="progress"
                          color="white"
                          indeterminate
                          light
                        ></v-progress-circular>
                    </div>
                    <router-view @progress="setProgress" />
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Alert from "@/components/Alert";

export default {
    name: "App",
    components: {
        Alert
    },
    data: () => ({
        progress: true
    }),
    methods: {
        setProgress (progress) {
            this.progress = progress;
        }
    }
}
</script>
