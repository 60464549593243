import Vue from "vue";

const clients = [
	{
		name: "Botika",
		base: "https://botika.online/",
		domains: ["sso.botika.online"],
		publicPath: `${process.env.BASE_URL}clients/botika/`,
		terms: "https://botika.online/terms-and-conditions-2/index.php",
		privacy: "https://botika.online/botika-privacy-policy/index.php"
	},
	{
		name: "Satkomindo",
		base: "https://satkomindo.com/",
		domains: ["sso.satkomindo.com"],
		publicPath: `${process.env.BASE_URL}clients/satkomindo/`,
		terms: null,
		privacy: null
	}
];

// Default Client
const defaultClient = clients.find(function (client) { return client.name === "Botika"; });

export const whitelabel = {
	getClient: function() {
		const hostname = window.location.hostname;

		const client = clients.find(function (client) {
			return client.domains.includes(hostname);
		});

		return client ? client : defaultClient;
	},
	getName: function () {
		return this.getClient().name;
	},
	getPublicPath: function () {
		return this.getClient().publicPath;
	},
	getPrivacyUrl: function () {
		return this.getClient().privacy;
	},
	getTermsUrl: function () {
		return this.getClient().terms;
	}
};

Vue.prototype.$whitelabel = whitelabel;

export default {
	install: function (Vue) {

		Vue.mixin({
			mounted: function () {
				let favicon = document.querySelector('link[rel="icon"]')
				if (!favicon) {
					favicon = document.createElement('link')
					favicon.setAttribute('rel', 'icon')
					favicon.setAttribute('type', 'image/png')
					document.head.appendChild(favicon)
				}
				favicon.setAttribute('href', `${this.$whitelabel.getPublicPath()}favicon.png`);
			},
		});
	}
}
