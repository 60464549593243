import { en } from "vuetify/lib/locale";

export default {
	...en,

	header: {
		title: 'Account {brand}'
	},

	error: {
		server: 'Server error, please try again later.'
	},

	signin: {
		alert: {
			access: {
				google: 'Failed to sign in using your Google Account',
				facebook: 'Failed to sign in using your Facebook Account'
			}
		},
		orother: 'or Sign In with',
		identifier: {
			title: 'Sign in',
			subtitle: 'Use your {brand} Account',
			continue: 'to continue to {app}',
			form: {
				identifier: {
					label: 'Email or username',
					error: {
						required: "Enter an email or username",
						notfound: "Couldn't find your {brand} Account",
						server: "Server error, please try again later"
					}
				},
				private: 'Not your computer? Use a Private Window to sign in.'
			},
			action: {
				signup: 'Create account',
				next: 'Next'
			}
		},
		password: {
			title: 'Welcome',
			form: {
				password: {
					label: 'Enter your password',
					error: {
						required: "Enter a password",
						unauthorized: "Wrong password. Try again or click Forgot password to reset it.",
						notfound: "Couldn't find your {brand} Account",
						server: "Server error, please try again later"
					}
				}
			},
			action: {
				'forgot-password': 'Forgot password?',
				next: 'Next'
			}
		},
		suspended: {
			title: 'Your {brand} Account is suspended',
			subtitle: "The {brand} Account {email} is now suspended. It looks like it was being used in way that violated {brand} policies.\r\n\r\nWe understand your account is important to you. So if you thik this was a mistake, please contact support@botika.online",
			action: {
				next: 'Try to contact'
			}
		},
		verify: {
			title: 'Verify your email address',
			subtitle: "To continue using your {brand} Account, please verify your email address: {email}",
			receive: 'An email with a verification code was just sent to {email}',
			form: {
				code: {
					label: 'Enter code',
					error: {
						required: "Enter a code",
						minlength: "Wrong number of digits. Try again",
						maxlength: "Wrong number of digits. Try again",
						numeric: "Code has numbers only. Try again",
						unauthorized: "Code has expired. Try again",
						notfound: "Wrong code. Try again",
						server: "Server error, please try again later"
					}
				}
			},
			action: {
				sendcode: 'Resend code',
				sendcodein: 'Resend code in {time}s',
				next: 'Next'
			}
		},
		success: {
			title: 'You are signed in now',
			subtitle: 'You have successfully signed into your account',
			action: {
				next: 'Continue'
			}
		}
	},

	'email-verify': {
		request: {
			title: 'Verify your email address',
			subtitle: 'Please verify the email address for {email}',
			action: {
				next: 'Verify'
			}
		},
		success: {
			title: 'Your email address has been verified',
			subtitle: 'Your email has been verified, now you can use your {brand} Account',
			action: {
				next: 'Continue'
			}
		}
	},

	'forgot-password': {
		request: {
			title: 'Account recovery',
			subtitle: 'This helps show that this account really belongs to you',
			form: {
				email: {
					label: 'Email address',
					error: {
						required: "Enter a email address",
						email: "Enter an valid email address",
						notfound: "This email address doesn’t match the one you provided. Try again.",
						server: "Server error, please try again later"
					}
				},
				hint: 'Please enter the email you use to sign in {brand}'
			},
			action: {
				signin: 'Sign in instead',
				next: 'Next'
			}
		},
		verify: {
			title: 'Account recovery',
			subtitle: 'An email with a verification code was just sent to {email}',
			form: {
				code: {
					label: 'Enter code',
					error: {
						required: "Enter a code",
						minlength: "Wrong number of digits. Try again",
						maxlength: "Wrong number of digits. Try again",
						numeric: "Code has numbers only. Try again",
						unauthorized: "Code has expired. Try again",
						notfound: "Wrong code. Try again",
						server: "Server error, please try again later"
					}
				}
			},
			action: {
				sendcode: 'Resend code',
				sendcodein: 'Resend code in {time}s',
				next: 'Next'
			}
		}
	},

	'change-password': {
		request: {
			title: 'Change password',
			subtitle: 'Create a new, strong password that you don’t use for other websites',
			form: {
				password: {
					label: 'Create password',
					error: {
						required: "Enter a password",
						minlength: "Use 8 characters or more for your password",
						server: "Server error, please try again later"
					}
				},
				passconf: {
					label: 'Confirm',
					error: {
						required: "Confirm your password",
						confirm: "Those passwords didn’t match. Try again.",
						server: "Server error, please try again later"
					}
				}
			},
			action: {
				next: 'Next'
			}
		},
		success: {
			title: 'Your passwod was changed',
			subtitle: 'Your password has been changed successfully. Use your new password to sign in',
			action: {
				next: 'Sign in instead'
			}
		}
	},

	signup: {
		caption: 'One account for all {brand} services',
		alert: {
			verify: "Please complete the following data, to continue sign in"
		},
		request: {
			title: 'Create your {brand} Account',
			continue: 'to continue to {app}',
			form: {
				firstname: {
					label: 'First name',
					error: {
						required: "Enter a first name",
						server: "Server error, please try again later"
					}
				},
				lastname: {
					label: 'Last name',
					error: {
						required: "Enter a last name",
						server: "Server error, please try again later"
					}
				},
				username: {
					label: 'Username',
					hint: "You can use letters & numbers",
					error: {
						required: "Enter a username",
						unique: "That username is taken. Try another.",
						minlength: "Use a minimum of 5 characters",
						maxlength: "Use a maximum of 100 characters",
						alphanum: "Sorry, only letters (a-z), and numbers (0-9), are allowed.",
						server: "Server error, please try again later"
					}
				},
				email: {
					label: 'Your email address',
					hint: "You'll need to confirm that this email belongs to you.",
					verify: "This email has been verified",
					error: {
						required: "Enter a email address",
						unique: "That email address is taken. Try another.",
						maxlength: "Use a maximum of 120 characters",
						email: "Please use a valid email address.",
						server: "Server error, please try again later"
					}
				},
				phone: {
					label: 'Phone number',
					error: {
						required: "Enter a phone number",
						unique: "That phone number is taken. Try another.",
						minlength: "Use a minimum of 6 characters",
						maxlength: "Use a maximum of 20 characters",
						valid: "Please enter a valid phone number",
						server: "Server error, please try again later"
					}
				},
				referral: {
					label: 'Referral code',
					error: {
						alphanum: "Sorry, only letters (a-z), and numbers (0-9), are allowed.",
						minlength: "Use a minimum of 6 characters",
						maxlength: "Use a maximum of 40 characters",
						notfound: "Wrong referral code. Try again",
						server: "Server error, please try again later"
					}
				},
				password: {
					label: 'Password',
					hint: 'Use 8 or more characters',
					error: {
						required: "Enter a password",
						minlength: "Use 8 characters or more for your password",
						server: "Server error, please try again later"
					}
				},
				passconf: {
					label: 'Confirm',
					error: {
						required: "Confirm your password",
						confirm: "Those passwords didn’t match. Try again.",
						server: "Server error, please try again later"
					}
				}
			},
			action: {
				signin: 'Sign in instead',
				next: 'Next'
			}
		},
		success: {
			title: 'Your {brand} Account was created',
			subtitle: 'Your {brand} Account has been created successfully. Use your {brand} Account to sign in',
			action: {
				next: 'Sign in instead'
			}
		}
	},

	rejected: {
		server: {
			title: 'Something went wrong',
			subtitle: 'Sorry, something went wrong there. Try again.',
			action: {
				next: 'Try again'
			}
		},
		cookie: {
			title: 'Cookies are disabled',
			subtitle: 'Your browser has cookies disabled. Make sure your cookies are enabled and try again.',
			action: {
				next: 'Try again'
			}
		},
		token: {
			title: 'Expired token',
			subtitle: 'Your session ended because the token has expired. Try again.',
			action: {
				next: 'Try again'
			}
		},
		'404': {
			title: '404. That’s an error.',
			subtitle: 'The requested URL was not found on this server. That’s all we know.',
			action: {
				next: 'Try again'
			}
		}
	},

	footer: {
		language: 'English',
		privacy: 'Privacy',
		terms: 'Terms'
	}
}
