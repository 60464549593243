import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { loadLanguageAsync } from "@/plugins/vuei18n";
// import ComponentSignIn from '@/views/SignIn.vue';
const ComponentSignIn = () => import(
    /* webpackChunkName: "views-signin" */ 
    /* webpackMode: "lazy" */
    '@/views/SignIn.vue'
);
// import ComponentSignInIdentifier from '@/views/SignIn/Identifier.vue';
const ComponentSignInIdentifier = () => import(
    /* webpackChunkName: "views-signin" */ 
    /* webpackMode: "lazy" */
    '@/views/SignIn/Identifier.vue'
);
// import ComponentSignInPassword from '@/views/SignIn/Password.vue';
const ComponentSignInPassword = () => import(
    /* webpackChunkName: "views-signin" */ 
    /* webpackMode: "lazy" */
    '@/views/SignIn/Password.vue'
);
// import ComponentSignInSuspended from '@/views/SignIn/Suspended.vue';
const ComponentSignInSuspended = () => import(
    /* webpackChunkName: "views-signin" */ 
    /* webpackMode: "lazy" */
    '@/views/SignIn/Suspended.vue'
);
// import ComponentSignInVerify from '@/views/SignIn/Verify.vue';
const ComponentSignInVerify = () => import(
    /* webpackChunkName: "views-signin" */ 
    /* webpackMode: "lazy" */
    '@/views/SignIn/Verify.vue'
);
// import ComponentSignInSuccess from '@/views/SignIn/Success.vue';
const ComponentSignInSuccess = () => import(
    /* webpackChunkName: "views-signin" */ 
    /* webpackMode: "lazy" */
    '@/views/SignIn/Success.vue'
);
// import ComponentEmailVerify from '@/views/EmailVerify.vue';
const ComponentEmailVerify = () => import(
    /* webpackChunkName: "views-email-verify" */ 
    /* webpackMode: "lazy" */
    '@/views/EmailVerify.vue'
);
// import ComponentEmailVerifyRequest from '@/views/EmailVerify/Request.vue';
const ComponentEmailVerifyRequest = () => import(
    /* webpackChunkName: "views-email-verify" */ 
    /* webpackMode: "lazy" */
    '@/views/EmailVerify/Request.vue'
);
// import ComponentEmailVerifySuccess from '@/views/EmailVerify/Success.vue';
const ComponentEmailVerifySuccess = () => import(
    /* webpackChunkName: "views-email-verify" */ 
    /* webpackMode: "lazy" */
    '@/views/EmailVerify/Success.vue'
);
// import ComponentForgotPassword from '@/views/ForgotPassword.vue';
const ComponentForgotPassword = () => import(
    /* webpackChunkName: "views-password" */ 
    /* webpackMode: "lazy" */
    '@/views/ForgotPassword.vue'
);
// import ComponentForgotPasswordRequest from '@/views/ForgotPassword/Request.vue';
const ComponentForgotPasswordRequest = () => import(
    /* webpackChunkName: "views-password" */ 
    /* webpackMode: "lazy" */
    '@/views/ForgotPassword/Request.vue'
);
// import ComponentForgotPasswordVerify from '@/views/ForgotPassword/Verify.vue';
const ComponentForgotPasswordVerify = () => import(
    /* webpackChunkName: "views-password" */ 
    /* webpackMode: "lazy" */
    '@/views/ForgotPassword/Verify.vue'
);
// import ComponentChangePassword from '@/views/ChangePassword.vue';
const ComponentChangePassword = () => import(
    /* webpackChunkName: "views-password" */ 
    /* webpackMode: "lazy" */
    '@/views/ChangePassword.vue'
);
// import ComponentChangePasswordRequest from '@/views/ChangePassword/Request.vue';
const ComponentChangePasswordRequest = () => import(
    /* webpackChunkName: "views-password" */ 
    /* webpackMode: "lazy" */
    '@/views/ChangePassword/Request.vue'
);
// import ComponentChangePasswordSuccess from '@/views/ChangePassword/Success.vue';
const ComponentChangePasswordSuccess = () => import(
    /* webpackChunkName: "views-password" */ 
    /* webpackMode: "lazy" */
    '@/views/ChangePassword/Success.vue'
);
// import ComponentSignUp from '@/views/SignUp.vue';
const ComponentSignUp = () => import(
    /* webpackChunkName: "views-signup" */ 
    /* webpackMode: "lazy" */
    '@/views/SignUp.vue'
);
// import ComponentSignUpRequest from '@/views/SignUp/Request.vue';
const ComponentSignUpRequest = () => import(
    /* webpackChunkName: "views-signup" */ 
    /* webpackMode: "lazy" */
    '@/views/SignUp/Request.vue'
);
// import ComponentSignUpSuccess from '@/views/SignUp/Success.vue';
const ComponentSignUpSuccess = () => import(
    /* webpackChunkName: "views-signup" */ 
    /* webpackMode: "lazy" */
    '@/views/SignUp/Success.vue'
);
// import ComponentRejected from '@/views/Rejected.vue';
const ComponentRejected = () => import(
    /* webpackChunkName: "views-rejected" */ 
    /* webpackMode: "lazy" */
    '@/views/Rejected.vue'
);
// import ComponentRejectedServer from '@/views/Rejected/Server.vue';
const ComponentRejectedServer = () => import(
    /* webpackChunkName: "views-rejected" */ 
    /* webpackMode: "lazy" */
    '@/views/Rejected/Server.vue'
);
// import ComponentRejectedCookie from '@/views/Rejected/Cookie.vue';
const ComponentRejectedCookie = () => import(
    /* webpackChunkName: "views-rejected" */ 
    /* webpackMode: "lazy" */
    '@/views/Rejected/Cookie.vue'
);
// import ComponentRejectedToken from '@/views/Rejected/Token.vue';
const ComponentRejectedToken = () => import(
    /* webpackChunkName: "views-rejected" */ 
    /* webpackMode: "lazy" */
    '@/views/Rejected/Token.vue'
);
// import ComponentRejected404 from '@/views/Rejected/404.vue';
const ComponentRejected404 = () => import(
    /* webpackChunkName: "views-rejected" */ 
    /* webpackMode: "lazy" */
    '@/views/Rejected/404.vue'
);

Vue.use(VueRouter);

const routes = [
    {
        path: '/signin',
        component: ComponentSignIn,
        props: route => ({ query: route.query }),
        beforeEnter: (to, from, next) => {
            if ( ! to.query.continue) {
                to.query.continue = Vue.prototype.$whitelabel.getClient().base;
            }

            if ( ! navigator.cookieEnabled) {
                next({ name: 'rejected-cookie', query: to.query });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'identifier',
                name: 'signin-identifier',
                component: ComponentSignInIdentifier,
                props: route => ({ query: route.query }),
                beforeEnter: (to, from, next) => {
                    store.dispatch('auth/info').then(() => {
                        next({ name: 'signin-success', query: to.query });
                    }).catch(error => {
                        if (error.response && error.response.status && error.response.status == 403) {
                            next({ name: 'signin-suspended', query: to.query });
                        } else if (error.response && error.response.status && error.response.status == 406) {
                            next({ name: 'signin-verify', query: {...to.query, ...{ token: error.response.data.data.token }}, params: { email: store.state.auth.identifier } });
                        } else if (error.response && error.response.status && error.response.status == 401) {
                            next();
                        } else {
                            next({ name: 'rejected-server', query: to.query });
                        }
                    });
                }
            },
            {
                path: 'password',
                name: 'signin-password',
                component: ComponentSignInPassword,
                props: route => ({ query: route.query }),
                beforeEnter: (to, from, next) => {
                    if ( ! store.state.auth.identifier) {
                        next({ name: 'signin-identifier', query: to.query });
                    } else {
                        store.dispatch('auth/info').then(() => {
                            next({ name: 'signin-success', query: to.query });
                        }).catch(error => {
                            if (error.response && error.response.status && error.response.status == 403) {
                                next({ name: 'signin-suspended', query: to.query });
                            } else if (error.response && error.response.status && error.response.status == 406) {
                                next({ name: 'signin-verify', query: {...to.query, ...{ token: error.response.data.data.token }}, params: { email: store.state.auth.identifier } });
                            } else if (error.response && error.response.status && error.response.status == 401) {
                                next();
                            } else {
                                next({ name: 'rejected-server', query: to.query });
                            }
                        });
                    }
                }
            },
            {
                path: 'suspended',
                name: 'signin-suspended',
                component: ComponentSignInSuspended,
                props: route => ({ query: route.query }),
                beforeEnter: (to, from, next) => {
                    store.dispatch('auth/info').then(() => {
                        next({ name: 'signin-success', query: to.query });
                    }).catch(error => {
                        if (error.response && error.response.status && error.response.status == 403) {
                            next();
                        } else if (error.response && error.response.status && error.response.status == 406) {
                            next({ name: 'signin-verify', query: {...to.query, ...{ token: error.response.data.data.token }}, params: { email: store.state.auth.identifier } });
                        } else if (error.response && error.response.status && error.response.status == 401) {
                            next({ name: 'signin-identifier', query: to.query });
                        } else {
                            next({ name: 'rejected-server', query: to.query });
                        }
                    });
                }
            },
            {
                path: 'verify',
                name: 'signin-verify',
                component: ComponentSignInVerify,
                props: route => ({ email: route.params.email, token: route.query.token, query: route.query }),
                beforeEnter: (to, from, next) => {
                    if ( ! to.query.token) {
                        store.dispatch('auth/info').then(() => {
                             next({ name: 'signin-success', query: to.query });
                        }).catch(error => {
                            if (error.response && error.response.status && error.response.status == 403) {
                                next({ name: 'signin-suspended', query: to.query });
                            } else if (error.response && error.response.status && error.response.status == 406) {
                                to.query.token = error.response.data.data.token;
                                store.dispatch('auth/signin-email-verify', { token: to.query.token }).then(response => {
                                    to.params.email = response.data.data.email;
                                    next();
                                }).catch(() => {
                                    next({ name: 'rejected-token', query: to.query });
                                });
                            } else if (error.response && error.response.status && error.response.status == 401) {
                                next({ name: 'signin-identifier', query: to.query });
                            } else {
                                next({ name: 'rejected-server', query: to.query });
                            }
                        });
                    } else {
                        store.dispatch('auth/signin-email-verify', { token: to.query.token }).then(response => {
                            to.params.email = response.data.data.email;
                            next();
                        }).catch(() => {
                            delete to.query.token;
                            next({ name: 'rejected-token', query: to.query });
                        });
                    }
                }
            },
            {
                path: 'success',
                name: 'signin-success',
                component: ComponentSignInSuccess,
                props: route => ({ query: route.query }),
                beforeEnter: (to, from, next) => {
                    store.dispatch('auth/info').then(() => {
                        next();
                    }).catch(error => {
                        if (error.response && error.response.status && error.response.status == 403) {
                            next({ name: 'signin-suspended', query: to.query });
                        } else if (error.response && error.response.status && error.response.status == 406) {
                            next({ name: 'signin-verify', query: {...to.query, ...{ token: error.response.data.data.token }}, params: { email: store.state.auth.identifier } });
                        } else if (error.response && error.response.status && error.response.status == 401) {
                            next({ name: 'signin-identifier', query: to.query });
                        } else {
                            next({ name: 'rejected-server', query: to.query });
                        }
                    });
                }
            },
            { path: "", redirect: { name: "rejected-404" } },
            { path: "/signin/*", redirect: { name: "rejected-404" } }
        ]
    },
    {
        path: '/email-verify',
        component: ComponentEmailVerify,
        props: route => ({ query: route.query }),
        beforeEnter: (to, from, next) => {
            if ( ! navigator.cookieEnabled) {
                next({ name: 'rejected-cookie', query: to.query });
            } else {
                next();
            }
        },
        children: [
            {
                path: '',
                name: 'email-verify-request',
                component: ComponentEmailVerifyRequest,
                props: route => ({ token: route.query.token, query: route.query }),
                beforeEnter: (to, from, next) => {
                    if ( ! to.query.token) {
                        next({ name: 'rejected-404', query: to.query });
                    } else {
                        store.dispatch('auth/email-verify', { token: to.query.token }).then(() => {
                            next();
                        }).catch(() => {
                            delete to.query.token;
                            next({ name: 'rejected-token', query: to.query });
                        });
                    }
                }
            },
            {
                path: 'success',
                name: 'email-verify-success',
                component: ComponentEmailVerifySuccess,
                props: route => ({ success: route.params.success, query: route.query }),
                beforeEnter: (to, from, next) => {
                    if ( ! to.params.success) {
                        next({ name: 'signin-identifier', query: to.query });
                    } else {
                        next();
                    }
                }
            },
            { path: "/email-verify/*", redirect: { name: "rejected-404" } }
        ]
    },
    {
        path: '/forgot-password',
        component: ComponentForgotPassword,
        props: route => ({ query: route.query }),
        beforeEnter: (to, from, next) => {
            if ( ! navigator.cookieEnabled) {
                next({ name: 'rejected-cookie', query: to.query });
            } else {
                store.dispatch('auth/info').then(() => {
                    next({ name: 'signin-success', query: to.query });
                }).catch(error => {
                    if (error.response && error.response.status && error.response.status == 403) {
                        next({ name: 'signin-suspended', query: to.query });
                    } else if (error.response && error.response.status && error.response.status == 406) {
                        next({ name: 'signin-verify', query: {...to.query, ...{ token: error.response.data.data.token }}, params: { email: store.state.auth.identifier } });
                    } else if (error.response && error.response.status && error.response.status == 401) {
                        next();
                    } else {
                        next({ name: 'rejected-server', query: to.query });
                    }
                });
            }
        },
        children: [
            {
                path: '',
                name: 'forgot-password-request',
                component: ComponentForgotPasswordRequest,
                props: route => ({ query: route.query }),
                beforeEnter: (to, from, next) => {
                    if ( ! store.state.auth.identifier) {
                        next({ name: 'signin-identifier', query: to.query });
                    } else {
                        next();
                    }
                }
            },
            {
                path: 'verify',
                name: 'forgot-password-verify',
                component: ComponentForgotPasswordVerify,
                props: route => ({ email: route.params.email, token: route.query.token, query: route.query }),
                beforeEnter: (to, from, next) => {
                    if ( ! to.query.token) {
                        next({ name: 'rejected-404', query: to.query });
                    } else if ( ! store.state.auth.identifier) {
                        delete to.query.token;
                        next({ name: 'signin-identifier', query: to.query });
                    } else {
                        store.dispatch('auth/forgot-password-verify', { token: to.query.token }).then(response => {
                            to.params.email = response.data.data.email;
                            next();
                        }).catch(() => {
                            delete to.query.token;
                            next({ name: 'rejected-token', query: to.query });
                        });
                    }
                }
            },
            { path: "/forgot-password/*", redirect: { name: "rejected-404" } }
        ]
    },
    {
        path: '/change-password',
        component: ComponentChangePassword,
        props: route => ({ query: route.query }),
        beforeEnter: (to, from, next) => {
            if ( ! navigator.cookieEnabled) {
                next({ name: 'rejected-cookie', query: to.query });
            } else {
                next();
            }
        },
        children: [
            {
                path: '',
                name: 'change-password-request',
                component: ComponentChangePasswordRequest,
                props: route => ({ token: route.query.token, query: route.query }),
                beforeEnter: (to, from, next) => {
                    if ( ! to.query.token) {
                        next({ name: 'rejected-404', query: to.query });
                    } else {
                        store.dispatch('auth/change-password-verify', { token: to.query.token }).then(() => {
                            next();
                        }).catch(() => {
                            delete to.query.token;
                            next({ name: 'rejected-token', query: to.query });
                        });
                    }
                }
            },
            {
                path: 'success',
                name: 'change-password-success',
                component: ComponentChangePasswordSuccess,
                props: route => ({ success: route.params.success, query: route.query }),
                beforeEnter: (to, from, next) => {
                    if ( ! to.params.success) {
                        next({ name: 'signin-identifier', query: to.query });
                    } else {
                        next();
                    }
                }
            },
            { path: "/change-password/*", redirect: { name: "rejected-404" } }
        ]
    },
    {
        path: '/signup',
        component: ComponentSignUp,
        props: route => ({ query: route.query }),
        beforeEnter: (to, from, next) => {
            if ( ! navigator.cookieEnabled) {
                next({ name: 'rejected-cookie', query: to.query });
            } else {
                store.dispatch('auth/info').then(() => {
                    next({ name: 'signin-success', query: to.query });
                }).catch(error => {
                    if (error.response && error.response.status && error.response.status == 403) {
                        next({ name: 'signin-suspended', query: to.query });
                    } else if (error.response && error.response.status && error.response.status == 406) {
                        next({ name: 'signin-verify', query: {...to.query, ...{ token: error.response.data.data.token }}, params: { email: store.state.auth.identifier } });
                    } else if (error.response && error.response.status && error.response.status == 401) {
                        next();
                    } else {
                        next({ name: 'rejected-server', query: to.query });
                    }
                });
            }
        },
        children: [
            {
                path: '',
                name: 'signup-request',
                component: ComponentSignUpRequest,
                props: route => ({ query: route.query })
            },
            {
                path: 'success',
                name: 'signup-success',
                component: ComponentSignUpSuccess,
                props: route => ({ success: route.params.success, query: route.query }),
                beforeEnter: (to, from, next) => {
                    if ( ! to.params.success) {
                        next({ name: 'signin-identifier', query: to.query });
                    } else {
                        next();
                    }
                }
            },
            { path: "/signup/*", redirect: { name: "rejected-404" } }
        ]
    },
    {
        path: '/rejected',
        component: ComponentRejected,
        children: [
            {
                path: 'server',
                name: 'rejected-server',
                component: ComponentRejectedServer,
                props: route => ({ query: route.query })
            },
            {
                path: 'cookie',
                name: 'rejected-cookie',
                component: ComponentRejectedCookie,
                props: route => ({ query: route.query }),
                beforeEnter: (to, from, next) => {
                    if (navigator.cookieEnabled) {
                        next({ name: 'signin-identifier', query: to.query });
                    } else {
                        next();
                    }
                },
            },
            {
                path: 'token',
                name: 'rejected-token',
                component: ComponentRejectedToken,
                props: route => ({ query: route.query })
            },
            {
                path: '404',
                name: 'rejected-404',
                component: ComponentRejected404,
                props: route => ({ query: route.query })
            },
            { path: "", redirect: { name: "rejected-404" } },
            { path: "/rejected/*", redirect: { name: "rejected-404" } }
        ]
    },
    { path: "*", redirect: { name: "signin-identifier", query: { continue: Vue.prototype.$whitelabel.getClient().base } } }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

// Remove body loading
router.beforeEach((to, from, next) => {
    document.body.classList.remove("is-loading");
    next();
});

router.beforeEach((to, from, next) => {
    loadLanguageAsync(store.state.app.locale).then(() => next());
});

export default router;
