<template>
	<v-alert
	  class="mb-5"
	  v-model="show"
      dense
      :dismissible="dismissible"
      :style="style"
      :type="type"
      transition="scale-transition"
    >
        {{ content }}
    </v-alert>
</template>

<script>
export default {
    name: "Alert",
    computed: {
    	style() {
    		if ( ! this.$vuetify.breakpoint.xsOnly) {
    			if (['signup-request', 'signup-success'].includes(this.$route.name)) {
    				return 'width:750px;max-width:750px;';
    			}
    			return 'width:450px;max-width:450px;';
    		}

    		return '';
    	},
    	show: {
			get () {
				return this.$store.getters['alert/show'];
			},
			set (value) {
				this.$store.dispatch((value ? 'alert/show' : 'alert/hide'));
			}
		},
		type() {
			return this.$store.getters['alert/type'];
		},
		content() {
			return this.$store.getters['alert/content'];
		},
		dismissible() {
			return this.$store.getters['alert/dismissible'];
		}
    }
}
</script>