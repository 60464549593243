import axios from "axios";
import jsonp from "jsonp";

let modules = {};

modules.namespaced = true;

modules.state = {
    identifier: null
};

modules.mutations = {
    setIdentifier(state, identifier) {
        state.identifier = identifier;
    }
};

modules.actions = {
    identifier: function({ commit }, { username = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/signin/verify/username',
                data: JSON.stringify({
                    username: username
                })
            })
            .then(response => {
                commit("setIdentifier", username);
                resolve(response);
            })
            .catch(error => {
                commit("setIdentifier", null);
                reject(error);
            });
        })
    },

    broker: function({ commit }) {
        return new Promise((resolve, reject) => {
            jsonp('/api/broker/attach', null, (err, data) => {
                if (err) {
                    reject(new Error(err.message, { response: { status: 500 } }));
                } else {
                    if (data.verify) {
                        axios({
                            method: 'post',
                            url: '/api/broker/verify',
                            data: JSON.stringify({
                                verify: data.verify
                            })
                        }).then(response => {
                            resolve();
                        }).catch(error => {
                            reject(new Error(err.message, { response: { status: 500 } }));
                        });
                    } else {
                        resolve();
                    }
                }
            });
        });
    },

    info: function({ commit }) {
        return new Promise((resolve, reject) => {
            this.dispatch('auth/broker').then(() => {
                axios({
                    method: 'get',
                    url: '/api/info'
                })
                .then(response => {
                    commit("setIdentifier", response.data.data.email);
                    resolve(response);
                })
                .catch(error => {
                    if (error.response && error.response.status == 403 && error.response.data && error.response.data.data && error.response.data.data.email) {
                        commit("setIdentifier", error.response.data.data.email);
                        reject(error);
                    } else if (error.response && error.response.status == 406 && error.response.data && error.response.data.data && error.response.data.data.email) {
                        commit("setIdentifier", error.response.data.data.email);
                        reject(error);
                    } else {
                        reject(error);
                    }
                });
            }).catch(error => {
                reject(error);
            });
        });
    },

    signin: function({ commit }, { username = '', password = '' }) {
        return new Promise((resolve, reject) => {
            this.dispatch('auth/broker').then(() => {
                axios({
                    method: 'post',
                    url: '/api/signin',
                    data: JSON.stringify({
                        username: username,
                        password: password
                    })
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        });
    },

    'signin-google': function({ commit }, { token = '' }) {
        return new Promise((resolve, reject) => {
            this.dispatch('auth/broker').then(() => {
                axios({
                    method: 'post',
                    url: '/api/signin/google',
                    data: JSON.stringify({
                        token: token
                    })
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        });
    },

    'signin-facebook': function({ commit }, { token = '' }) {
        return new Promise((resolve, reject) => {
            this.dispatch('auth/broker').then(() => {
                axios({
                    method: 'post',
                    url: '/api/signin/facebook',
                    data: JSON.stringify({
                        token: token
                    })
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        });
    },

    'email-request': function({ commit }, { email = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/signin/email/request',
                data: JSON.stringify({
                    email: email
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    'signin-email-verify': function({ commit }, { token = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/signin/email/verify',
                data: JSON.stringify({
                    token: token
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    'email-verify-request': function({ commit }, { token = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/email',
                data: JSON.stringify({
                    token: token
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    'email-verify': function({ commit }, { token = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/email/verify',
                data: JSON.stringify({
                    token: token
                })
            })
            .then(response => {
                commit("setIdentifier", response.data.data.email);
                resolve(response);
            })
            .catch(error => {
                commit("setIdentifier", null);
                reject(error);
            });
        });
    },

    'forgot-password': function({ commit }, { username = '', email = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/forgot-password',
                data: JSON.stringify({
                    username: username,
                    email: email
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    'forgot-password-verify': function({ commit }, { token = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/forgot-password/verify',
                data: JSON.stringify({
                    token: token
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    'code-verify': function({ commit }, { token = '', code = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/code/verify',
                data: JSON.stringify({
                    token: token,
                    code: code
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    'change-password': function({ commit }, { token = '', password = '', passconf = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/change-password',
                data: JSON.stringify({
                    token: token,
                    password: password,
                    passconf: passconf
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    'change-password-verify': function({ commit }, { token = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/change-password/verify',
                data: JSON.stringify({
                    token: token
                })
            })
            .then(response => {
                commit("setIdentifier", response.data.data.email);
                resolve(response);
            })
            .catch(error => {
                commit("setIdentifier", null);
                reject(error);
            });
        });
    },

    signup: function({ commit }, { provider = 'botika', token = 'botika', firstname = '', lastname = '', username = '', email = '', phonecode = '', phone = '', referral = '', password = '', passconf = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/signup',
                data: JSON.stringify({
                    provider: provider,
                    token: token,
                    firstname: firstname,
                    lastname: lastname,
                    username: username,
                    email: email,
                    phonecode: phonecode,
                    phone: phone,
                    referral: referral,
                    password: password,
                    passconf: passconf
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    'google-identifier': function({ commit }, { token = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/google/identifier',
                data: JSON.stringify({
                    token: token
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    'facebook-identifier': function({ commit }, { token = '' }) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/facebook/identifier',
                data: JSON.stringify({
                    token: token
                })
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    signout: function({ commit }) {
        return new Promise((resolve, reject) => {
            this.dispatch('auth/broker').then(() => {
                axios({
                    method: 'post',
                    url: '/api/signout'
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        })
    }
};

export default modules;