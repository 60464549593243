import Vue from "vue";
import Vuex from "vuex";
import VuexPersistedState from "vuex-persistedstate";
import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import alert from '@/store/modules/alert';

Vue.use(Vuex);

const store = new Vuex.Store({ 
	modules: {
		app,
		auth,
		alert
	},
	plugins: [
		// locale
		VuexPersistedState({
	        key: 'vuex.app',
	        paths: ['app'],
	        storage: window.localStorage
	    }),
	    // auth
	    VuexPersistedState({
	        key: 'vuex.auth',
	        paths: ['auth'],
	        storage: window.sessionStorage
	    })
	]
});

export default store;
