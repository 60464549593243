import { loadLanguageAsync } from "@/plugins/vuei18n";

let modules = {};

modules.namespaced = true;

modules.state = {
    locale: 'en',
    web: [
        // { name: 'Chatbots', url: 'botmaster.botika.online' },
        // { name: 'Chatbot Designer', url: 'platform.botika.online' },
        // { name: 'Omnichannel', url: 'botmaster.botika.online' },
        // { name: 'Olshop', url: 'olshop.ai' },
        // { name: 'Text to Speech', url: 'tts.botika.online' },
    ]
};

modules.mutations = {
    setLocale(state, locale) {
        state.locale = locale;
    }
};

modules.actions = {
    setLocale: function({ commit }, { locale = 'en' }) {
        loadLanguageAsync(locale).then(() => {
            commit("setLocale", locale);
        });
    },
};

export default modules;