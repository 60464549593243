let modules = {};

modules.namespaced = true;

modules.state = {
    show: false,
    dismissible: false,
    type: 'info',
    content: ''
};

modules.getters = {
    show: (state) => state.show,
    dismissible: (state) => state.dismissible,
    type: (state) => state.type,
    content: (state) => state.content
};

modules.mutations = {
    setShow(state, show) {
        state.show = show;
    },
    setDismissible(state, dismissible) {
        state.dismissible = dismissible;
    },
    setType(state, type) {
        state.type = type;
    },
    setContent(state, content) {
        state.content = content;
    }
};

modules.actions = {
    set: function({ commit }, { type = 'info', content = '', dismissible = false }) {
        commit("setType", type);
        commit("setContent", content);
        commit("setDismissible", dismissible);
    },
    show: function({ commit }) {
        commit("setShow", true);
    },
    hide: function({ commit }) {
        commit("setShow", false);
    },
    clear: function({ commit }) {
        commit("setType", "info");
        commit("setContent", "");
        commit("setDismissible", false);
    }
};

export default modules;